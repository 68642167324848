import React from "react";
//Auth Part
const RegisterUsers = React.lazy(() =>
  import("../pages/shared/auth/RegisterUsers")
);
const ChangePassword = React.lazy(() =>
  import("../pages/shared/auth/ChangePassword")
);
//wallets
const ManageClientWallets = React.lazy(() =>
  import("../pages/shared/wallets/ManageClientWallets")
);
const UpdateCredit = React.lazy(() =>
  import("../pages/shared/wallets/UpdateCredit")
);
const TransactionHistoryGrid = React.lazy(() =>
  import("../pages/shared/wallets/TransactionHistoryGrid")
);
//dashboard part
const DashBoard = React.lazy(() => import("../pages/shared/dashboard/Index"));

//master
const Company = React.lazy(() => import("../pages/shared/masters/Company"));
const Groups = React.lazy(() =>
  import("../pages/shared/masters/groups/GroupsGrid")
);
const CreateGroups = React.lazy(() =>
  import("../pages/shared/masters/groups/CreateGroups")
);
const Ledgers = React.lazy(() =>
  import("../pages/shared/masters/ledgers/LedgersGrid")
);
const CreateLedgers = React.lazy(() =>
  import("../pages/shared/masters/ledgers/CreateLedgers")
);
const RetireLedgersGrid = React.lazy(() =>
  import("../pages/shared/masters/ledgers/RetireLedgersGrid")
);
const CostCenterGrid = React.lazy(() =>
  import("../pages/shared/masters/costcenter/CostCenterGrid")
);
const CreateCostCenter = React.lazy(() =>
  import("../pages/shared/masters/costcenter/CreateCostCenter")
);

//Voucher Settings
const CreditNoteSettings = React.lazy(() =>
  import(
    "../pages/products/accountswonders/vouchersettings/CreditNoteVoucherSettings"
  )
);
const DebitNoteSettings = React.lazy(() =>
  import(
    "../pages/products/accountswonders/vouchersettings/DebitNoteVoucherSettings"
  )
);
const DualTransactionPaymentSettings = React.lazy(() =>
  import("../pages/shared/vouchersettings/DualTransactionPaymentSettings")
);
const DualTransctionReceiptSettings = React.lazy(() =>
  import("../pages/shared/vouchersettings/DualTransctionReceiptSettings")
);
const JournalSettings = React.lazy(() =>
  import("../pages/shared/vouchersettings/JournalSettings")
);
const ContraSettings = React.lazy(() =>
  import("../pages/shared/vouchersettings/ContraSettings")
);
const PaymentSettings = React.lazy(() =>
  import("../pages/shared/vouchersettings/PaymentSettings")
);
const ReceiptSettings = React.lazy(() =>
  import("../pages/shared/vouchersettings/ReceiptSettings")
);
const ProformaSettings = React.lazy(() =>
  import("../pages/shared/vouchersettings/ProformaSettings")
);
const PurchaseEntry = React.lazy(() =>
  import("../pages/shared/vouchersettings/PurchaseEntrySettings")
);
const SalesInvoice = React.lazy(() =>
  import("../pages/shared/vouchersettings/SalesInvoiceSettings")
);
const TradingPurchaseEntrySettings = React.lazy(() =>
  import(
    "../pages/products/accountswonders/vouchersettings/TradingPurchaseEntrySettings"
  )
);
const TradingSalesInvoiceSettings = React.lazy(() =>
  import(
    "../pages/products/accountswonders/vouchersettings/TradingSalesInvoiceSettings"
  )
);
//Transaction
const SalesInvoiceGrid = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/salesinvoice/SalesInvoiceGrid"
  )
);

const CreateSalesInvoice = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/salesinvoice/CreateSalesInvoice"
  )
);
const ProformaInvoiceGrid = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/proformainvoice/ProformaInvoiceGrid"
  )
);

const CreateProformaInvoice = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/proformainvoice/CreateProformaInvoice"
  )
);
const PurchaseEntryGrid = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/purchaseentry/PurchaseEntryGrid"
  )
);
const CreatePurchaseEntry = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/purchaseentry/CreatePurchaseEntry"
  )
);

const CreatePaymentReceipt = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/paymentreceipt/CreatePaymentReceipt"
  )
);
const PaymentReceiptGrid = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/paymentreceipt/PaymentReceiptGrid"
  )
);
const JournalContraGrid = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/journalcontra/JournalContraGrid"
  )
);
const CreateJournalContra = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/journalcontra/CreateJournalContra"
  )
);
const DualTransactionGrid = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/dualtransaction/DualTransactionGrid"
  )
);
const CreateDualTransaction = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/dualtransaction/CreateDualTransaction"
  )
);
const DebitCreditNoteGrid = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/debitnotecreditnote/DebitCreditNoteGrid"
  )
);
const CreateDebitCreditNote = React.lazy(() =>
  import(
    "../pages/shared/transaction/accounttransaction/debitnotecreditnote/CreateDebitCreditNote"
  )
);

//Reports
const LedgerReports = React.lazy(() =>
  import("../pages/shared/reports/LedgerReports")
);
const TrailBalance = React.lazy((res) =>
  import("../pages/shared/reports/TrailBalance")
);
const ProfitLoss = React.lazy(() =>
  import("../pages/shared/reports/ProfitLoss")
);
const BalanceSheet = React.lazy(() =>
  import("../pages/shared/reports/BalanceSheet")
);

//GST
const GstAuthentication = React.lazy(() =>
  import("../pages/shared/gst/GstAuthentication")
);
const AllGstServices = React.lazy(() =>
  import("../pages/shared/gst/AllGstServices")
);
const GSTR1Services = React.lazy(() =>
  import("../pages/shared/gst/components/Gstr1Components/GSTR1Services")
);

//Eway Bill
const GenerateEwayBillGrid = React.lazy(() =>
  import("../pages/shared/ewaybill/GenerateEwayBillGrid")
);
const GenerateEwayBill = React.lazy(() =>
  import("../pages/products/accountswonders/ewayBill/GenerateEwayBill")
);
const BillsByDateGrid = React.lazy(() =>
  import("../pages/shared/ewaybill/BillsByDateGrid")
);
const TransporterByDateGrid = React.lazy(() =>
  import("../pages/shared/ewaybill/TransporterByDateGrid")
);
const TransporterByStateGrid = React.lazy(() =>
  import("../pages/shared/ewaybill/TransporterByStateGrid")
);
const CancelEwayBill = React.lazy(() =>
  import("../pages/shared/ewaybill/CancelEwayBill")
);
const UpdatePartB = React.lazy(() =>
  import("../pages/shared/ewaybill/UpdatePartB")
);

//MultiReport Printing
const ReportPrinting = React.lazy(() =>
  import("../pages/products/accountswonders/reportprinting/ReportPrinting")
);

//Trading
const ManageTrading = React.lazy(() =>
  import("../pages/products/accountswonders/trading/ManageTrading")
);
const ItemGrid = React.lazy(() =>
  import("../pages/products/accountswonders/trading/item/ItemGrid")
);
const CreateItem = React.lazy(() =>
  import("../pages/products/accountswonders/trading/item/CreateItem")
);
const ItemGroupGrid = React.lazy(() =>
  import("../pages/products/accountswonders/trading/itemgroup/ItemGroupGrid")
);
const CreateItemGroup = React.lazy(() =>
  import("../pages/products/accountswonders/trading/itemgroup/CreateItemGroup")
);
const PurchaseEntryGridTrading = React.lazy(() =>
  import(
    "../pages/products/accountswonders/trading/purchaseentry/PurchaseEntryGrid"
  )
);

const CreatePurchaseEntryTrading = React.lazy(() =>
  import(
    "../pages/products/accountswonders/trading/purchaseentry/CreatePurchaseEntry"
  )
);
const SalesInvoiceGridTrading = React.lazy(() =>
  import(
    "../pages/products/accountswonders/trading/salesinvoice/SalesInvoiceGrid"
  )
);
const CreateSlesInvoiceTrading = React.lazy(() =>
  import(
    "../pages/products/accountswonders/trading/salesinvoice/CreateSlesInvoice"
  )
);

const StokeRegisaterGrid = React.lazy(() =>
  import(
    "../pages/products/accountswonders/trading/stokeregister/StokeRegisaterGrid"
  )
);
const AccountsWondersRoutes = [
  {
    path: "/dashboard/register_users",
    name: "registerUsers",
    element: RegisterUsers,
  },
  {
    path: "/dashboard/change_password",
    name: "changepassword",
    element: ChangePassword,
  },
  {
    path: "/dashboard/wallet_manageusers",
    name: "managewallets",
    element: ManageClientWallets,
  },
  {
    path: "/dashboard/wallet_updatecredit",
    name: "updatecredit",
    element: UpdateCredit,
  },
  {
    path: "/dashboard/wallet_TransactionHistoryGrid",
    name: "TransactionHistoryGrid",
    element: TransactionHistoryGrid,
  },
  {
    path: "/dashboard/dshboardhome",
    name: "dashboard",
    element: DashBoard,
  },
  {
    path: `/dashboard/masters_company`,
    name: "company",
    element: Company,
  },
  {
    path: `/dashboard/masters_groups`,
    name: "groups",
    element: Groups,
  },
  {
    path: `/dashboard/masters_creategroups`,
    name: "create groups",
    element: CreateGroups,
  },
  {
    path: `/dashboard/masters_ledgers`,
    name: "ledgers",
    element: Ledgers,
  },
  {
    path: `/dashboard/masters_createledgers`,
    name: "create ledgers",
    element: CreateLedgers,
  },
  {
    path: `/dashboard/masters_retireledgers`,
    name: "Retired ledgers",
    element: RetireLedgersGrid,
  },

  {
    path: `/dashboard/masters_costcenterGrid`,
    name: "costcentergrid",
    element: CostCenterGrid,
  },
  {
    path: `/dashboard/masters_createcostcenter`,
    name: "costcentergrid",
    element: CreateCostCenter,
  },
  {
    path: `/dashboard/masters_createcostcenter`,
    name: "costcentergrid",
    element: CreateCostCenter,
  },

  //Voucher Settings

  {
    path: `/dashboard/voucher_settings_creditnote_settings`,
    name: "creditnotesettings",
    element: CreditNoteSettings,
  },
  {
    path: `/dashboard/voucher_settings_debitnote_settings`,
    name: "debitnotesettings",
    element: DebitNoteSettings,
  },
  {
    path: `/dashboard/voucher_settings_dualtransactionpayment_settings`,
    name: "dualtransactionpaymentsettings",
    element: DualTransactionPaymentSettings,
  },
  {
    path: `/dashboard/voucher_settings_dualtransactionreceipt_settings`,
    name: "dualtransctionreceiptsettings",
    element: DualTransctionReceiptSettings,
  },
  {
    path: `/dashboard/voucher_settings_journal_settings`,
    name: "journalsettings",
    element: JournalSettings,
  },
  {
    path: `/dashboard/voucher_settings_contra_settings`,
    name: "contrasettings",
    element: ContraSettings,
  },
  {
    path: `/dashboard/voucher_settings_payment_settings`,
    name: "paymentsettings",
    element: PaymentSettings,
  },
  {
    path: `/dashboard/voucher_settings_receipt_settings`,
    name: "receiptsettings",
    element: ReceiptSettings,
  },
  {
    path: `/dashboard/voucher_settings_proforma_settings`,
    name: "proformasettings",
    element: ProformaSettings,
  },
  {
    path: `/dashboard/voucher_settings_tradingpurchase_settings`,
    name: "tradingpurchaseentry",
    element: TradingPurchaseEntrySettings,
  },
  {
    path: `/dashboard/voucher_settings_salesinvoice_settings`,
    name: "salesinvoice",
    element: SalesInvoice,
  },
  {
    path: `voucher_settings_purchase_settings`,
    name: "purchasesettings",
    element: PurchaseEntry,
  },
  {
    path: `/dashboard/voucher_settings_purchaseentry_settings`,
    name: "tradingpurchaseentry",
    element: TradingPurchaseEntrySettings,
  },
  {
    path: `/dashboard/voucher_settings_sales_settings`,
    name: "salesinvoice",
    element: TradingSalesInvoiceSettings,
  },
  //transaction
  {
    path: `/dashboard/transaction/accounttransaction_SalesInvoice`,
    name: "salesinvoicegrid",
    element: SalesInvoiceGrid,
  },
  {
    path: `/dashboard/transaction/accounttransaction_CreateSalesInvoice`,
    name: "createsalesinvoice",
    element: CreateSalesInvoice,
  },
  {
    path: `/dashboard/transaction/accounttransaction_proformainvoice`,
    name: "proformainvoicegrid",
    element: ProformaInvoiceGrid,
  },
  {
    path: `/dashboard/transaction/accounttransaction_createproformainvoice`,
    name: "createproformainvoice",
    element: CreateProformaInvoice,
  },
  {
    path: `/dashboard/transaction/accounttransaction_purchaseentryinvoice`,
    name: "purchaseentrygrid",
    element: PurchaseEntryGrid,
  },
  {
    path: `/dashboard/transaction/accounttransaction_createpurchaseentry`,
    name: "createpurchaseentry",
    element: CreatePurchaseEntry,
  },
  {
    path: `/dashboard/transaction/accounttransaction_paymentreceiptinvoice`,
    name: "paymentreceiptgrid",
    element: PaymentReceiptGrid,
  },
  {
    path: `/dashboard/transaction/accounttransaction_createPaymentReceipt`,
    name: "createpaymentreceipt",
    element: CreatePaymentReceipt,
  },
  {
    path: `/dashboard/transaction/accounttransaction_jpurnalcontrainvoice`,
    name: "journalcontragrid",
    element: JournalContraGrid,
  },
  {
    path: `/dashboard/transaction/accounttransaction_createjournalcontra`,
    name: "createjournalcontra",
    element: CreateJournalContra,
  },
  {
    path: `/dashboard/transaction/accounttransaction_dualtransactioninvoice`,
    name: "dualtransactiongrid",
    element: DualTransactionGrid,
  },
  {
    path: `/dashboard/transaction/accounttransaction_Createdualtransaction`,
    name: "createDualTransaction",
    element: CreateDualTransaction,
  },
  {
    path: `/dashboard/transaction/accounttransaction_debitcreditnoteinvoice`,
    name: "debitcreditnotegrid",
    element: DebitCreditNoteGrid,
  },
  {
    path: `/dashboard/transaction/accounttransaction_createdebitcreditnote`,
    name: "createdebitcreditnote",
    element: CreateDebitCreditNote,
  },

  //Reports
  {
    path: `/dashboard/transaction/reports_ledgersreports`,
    name: "ledgerreports",
    element: LedgerReports,
  },
  {
    path: `/dashboard/transaction/reports_trailbalancereports`,
    name: "trailbalance",
    element: TrailBalance,
  },
  {
    path: `/dashboard/transaction/reports_profitloss`,
    name: "profitloss",
    element: ProfitLoss,
  },
  {
    path: `/dashboard/transaction/reports_balancesheet`,
    name: "balanceheet",
    element: BalanceSheet,
  },
  //GST
  {
    path: `/dashboard/transaction/gst_gstAutghentication`,
    name: "gstauthentication",
    element: GstAuthentication,
  },
  {
    path: `/dashboard/transaction/gst_allgstservices`,
    name: "gstservices",
    element: AllGstServices,
  },
  {
    path: `/dashboard/transaction/gst_gstr1Services`,
    name: "gstr1services",
    element: GSTR1Services,
  },

  {
    path: `/dashboard/ewaybill/GenerateEwayBillGrid`,
    name: "GenerateEwayBillGrid",
    element: GenerateEwayBillGrid,
  },
  {
    path: `/dashboard/ewaybill/GenerateEwayBillForAccountsWonders`,
    name: "GenerateEwayBill",
    element: GenerateEwayBill,
  },
  {
    path: `/dashboard/ewaybill/BillsByDateGrid`,
    name: "BillsByDateGrid",
    element: BillsByDateGrid,
  },
  {
    path: `/dashboard/ewaybill/TransporterByDateGrid`,
    name: "TransporterByDateGrid",
    element: TransporterByDateGrid,
  },
  {
    path: `/dashboard/ewaybill/TransporterByStateGrid`,
    name: "TransporterByStateGrid",
    element: TransporterByStateGrid,
  },
  {
    path: `/dashboard/ewaybill/CancelEwayBill`,
    name: "CancelEwayBill",
    element: CancelEwayBill,
  },
  {
    path: `/dashboard/ewaybill/UpdatePartB`,
      name: "UpdatePartB",
    element: UpdatePartB,
  },

  //MultiReport Printing
  {
    path: `/dashboard/transaction/ewport_reportprinting`,
    name: "reportprinting",
    element: ReportPrinting,
  },

  //Trading
  //trading
  {
    path: `/dashboard/trading/manage_trading`,
    name: "manageTrading",
    element: ManageTrading,
  },
  {
    path: `/dashboard/trading/item_grid`,
    name: "itemgrid",
    element: ItemGrid,
  },
  {
    path: `/dashboard/trading/create_item`,
    name: "createitem",
    element: CreateItem,
  },
  {
    path: `/dashboard/trading/itemgroup_grid`,
    name: "itemgroupgrid",
    element: ItemGroupGrid,
  },
  {
    path: `/dashboard/trading/createitemgroup_grid`,
    name: "Createitemgroupgrid",
    element: CreateItemGroup,
  },
  {
    path: `/dashboard/trading/purchaseentrytrading_grid`,
    name: "purchaseentrytradinggrid",
    element: PurchaseEntryGridTrading,
  },
  {
    path: `/dashboard/trading/createpurchase_trading`,
    name: "createpurchaseentrytrading",
    element: CreatePurchaseEntryTrading,
  },
  {
    path: `/dashboard/trading/salesinvoicetrading_grid`,
    name: "salesinvoicegridtrading",
    element: SalesInvoiceGridTrading,
  },
  {
    path: `/dashboard/trading/createsalesinvoice_trading`,
    name: "createsalesinvoicetrading",
    element: CreateSlesInvoiceTrading,
  },
  {
    path: `/dashboard/trading/stokeregister_grid`,
    name: "stokeregisatergrid",
    element: StokeRegisaterGrid,
  },
];
export default AccountsWondersRoutes;
