import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { _products } from "./Constant";
import { useColorModes } from "@coreui/react";
import AccountsWondersRoutes from "./menuItems&routes/AcountsWondersRoute";
import TransWondersRoutes from "./menuItems&routes/TransWondersRoutes";
import SharedPath from "./helper/SharedPath";
import WebSiteLoader from "./components/WebSiteLoader";
import ThemeProvider from "./components/ThemeProvider";
import { Helmet } from "react-helmet";
import { WalletServices } from "./pages/shared/services/WalletServices";
import { TourProvider } from "@reactour/tour";
import "../src/assets/scss/style.scss";
import "../src/assets/css/App.css";
import { CButton } from "@coreui/react";
import DocumentRoutes from "./menuItems&routes/DocumentRoutes";
import _steps from "./components/TourProviderSteps";
import _taxwondersRoutes from "./menuItems&routes/TaxWondersRoutes";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
const ErrorBoundary = React.lazy(() => import("./components/ErrorComponent"));
const Login = React.lazy(() => import("./pages/shared/auth/Login"));
const AdminControl = React.lazy(() =>
  import("./pages/shared/admincontrol/AdminControl")
);
const DocumentationHome = React.lazy(() =>
  import("./layout/Documentation/DocumentationHome")
);
const RegisterUsers = React.lazy(() =>
  import("./pages/shared/auth/Registration/RegisterUsers")
);
const LayOut = React.lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./layout/Layout")), 1000);
  });
});
const Index = React.lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./layout/home/Index")), 10);
  });
});
let routes = [];
let documentRoutes = [];
function App() {
  const [walletBalance, setWalletBalance] = useState(null);
  const [virtualWalletBalance, setVirtualWalletBalance] = useState(null);
  const [currentWalletId, setCurrentWalletId] = useState(null);
  const [colorMode, setThemeColor] = useState("light");
  const [chatColor, setChatColor] = useState("white");
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const [productName, setProductName] = useState(
    SharedPath === _products.transWonders
      ? _products.transWonders
      : SharedPath === _products.taxWonders
      ? _products.taxWonders
      : _products.accountsWonders
  );
  let customStyles = {
    input: (provided) => ({
      ...provided,
      color: colorMode === "light" ? "#212631" : "#F8F8F9", // Change the color to your desired color
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.selectProps.menuIsOpen
        ? colorMode === "light"
          ? "#FFFFFF"
          : "#212631"
        : colorMode === "light"
        ? "#FFFFFF"
        : "#212631",
      color: state.selectProps.menuIsOpen
        ? colorMode === "light"
          ? "#333"
          : "#fff"
        : colorMode === "light"
        ? "#666"
        : "#ccc",
      border: colorMode === "light" ? "3px solid #F3F4F7" : "3px solid #282D37",
    }),

    menu: (provided, state) => ({
      ...provided,
      backgroundColor: colorMode === "light" ? "#fff" : "#212631",
      color: colorMode === "light" ? "#333" : "#fff",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? colorMode === "light"
          ? "#DEEBFF"
          : "#555"
        : colorMode === "light"
        ? "#fff"
        : "#333",
      color: state.isFocused
        ? colorMode === "light"
          ? "#333"
          : "#fff"
        : colorMode === "light"
        ? "#666"
        : "#ccc",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: colorMode === "light" ? "#333" : "#fff", // Change the selected text color
    }),
  };
  const [themeStyle, setThemeStyle] = useState(customStyles);
  if (SharedPath === _products.transWonders) {
    routes = TransWondersRoutes;
    documentRoutes = DocumentRoutes;
  }
  if (SharedPath === _products.accountsWonders) {
    routes = AccountsWondersRoutes;
    documentRoutes = DocumentRoutes;
  }
  if (SharedPath === _products.taxWonders) {
    routes = _taxwondersRoutes;
  }

  const { isColorModeSet, setColorMode } = useColorModes(
    "coreui-free-react-admin-template-theme"
  );
  const storedTheme = useSelector((state) => state.theme);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
    const theme =
      urlParams.get("theme") &&
      urlParams.get("theme").match(/^[A-Za-z0-9\s]+/)[0];
    if (theme) {
      setColorMode(theme);
    }
    if (isColorModeSet()) {
      return;
    }
    setColorMode(storedTheme);
  }, []);
  const updateThirPartyTheme = (colorMode) => {
    setThemeColor(colorMode);
    customStyles = {
      input: (provided) => ({
        ...provided,
        color: colorMode === "light" ? "#212631" : "#F8F8F9", // Change the color to your desired color
      }),
      control: (provided, state) => ({
        ...provided,

        backgroundColor: state.selectProps.menuIsOpen
          ? colorMode === "light"
            ? "#FFFFFF"
            : "#212631"
          : colorMode === "light"
          ? "#FFFFFF"
          : "#212631",
        color: state.selectProps.menuIsOpen
          ? colorMode === "light"
            ? "#333"
            : "#fff"
          : colorMode === "light"
          ? "#666"
          : "#ccc",
        border:
          colorMode === "light" ? "3px solid #F3F4F7" : "3px solid #282D37",
      }),

      menu: (provided, state) => ({
        ...provided,
        backgroundColor: colorMode === "light" ? "#fff" : "#212631",
        color: colorMode === "light" ? "#333" : "#fff",
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
          ? colorMode === "light"
            ? "#DEEBFF"
            : "#555"
          : colorMode === "light"
          ? "#fff"
          : "#333",
        color: state.isFocused
          ? colorMode === "light"
            ? "#333"
            : "#fff"
          : colorMode === "light"
          ? "#666"
          : "#ccc",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: colorMode === "light" ? "#333" : "#fff", // Change the selected text color
      }),
    };
    setThemeStyle(customStyles);

    if (colorMode === "light") {
      setChatColor("black");
    } else {
      setChatColor("white");
    }
  };

  const updateCurrentBalance = async (status) => {
    if (status === true) {
      await WalletServices.getCurrentWalletInfo().then((res) => {
        if (res.basicResponse.isSuccess) {
          setWalletBalance(res.walletInfo.balance);
          setVirtualWalletBalance(res.walletInfo.virtualWalletBalance);
          setCurrentWalletId();
        }
      });
    }
  };
  const value = {
    customStyles: themeStyle,
    colorMode: colorMode,
    updateThirPartyTheme: updateThirPartyTheme,
    updateCurrentBalance: updateCurrentBalance,
    walletBalance: walletBalance,
    virtualWalletBalance: virtualWalletBalance,
    currentWalletId: currentWalletId,
    chatColor: chatColor,
  };
  return (
    <>
      <Helmet>
        <title> {productName}</title>
      </Helmet>
      <TourProvider
        steps={_steps}
        className="custom-tour"
        afterOpen={disableBody}
        beforeClose={enableBody}
        disableDotsNavigation
        prevButton={({ currentStep, setCurrentStep, steps }) => {
          const first = currentStep === 0;
          return (
            <CButton
              color="primary"
              onClick={() => {
                if (first) {
                  setCurrentStep((s) => steps.length - 1);
                } else {
                  setCurrentStep((s) => s - 1);
                }
              }}
              disabled={first}
            >
              BACK
            </CButton>
          );
        }}
        nextButton={({
          Button,
          currentStep,
          stepsLength,
          setIsOpen,
          setCurrentStep,
          steps,
        }) => {
          const last = currentStep === stepsLength - 1;
          return (
            <CButton
              color="primary"
              onClick={() => {
                if (last) {
                  setIsOpen(false);
                } else {
                  setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1));
                }
              }}
            >
              {last ? "DONE" : "NEXT"}
            </CButton>
          );
        }}
        styles={{
          popover: (base) => ({
            ...base,
            "--reactour-accent": "#5856D6",
            borderRadius: 10,
            width: "100%",
          }),
          maskArea: (base) => ({ ...base, rx: 10 }),
          maskWrapper: (base) => ({ ...base, color: "#5856D6" }),
          badge: (base) => ({ ...base, left: "auto", right: "-0.8125em" }),
          controls: (base) => ({ ...base, marginTop: 100 }),
          content: (base) => ({ ...base, color: "#5856D6" }),
          close: (base) => ({
            ...base,
            right: "auto",
            left: 8,
            top: 8,
            color: "#5856D6",
          }),
        }}
      >
        <ThemeProvider.Provider value={value}>
          <Router>
            <ErrorBoundary>
              <Suspense fallback={<WebSiteLoader />}>
                <Routes>
                  <Route path={"/"} name="home" element={<Index />}>
                    {/* <Route path={"/about"} name={"about"} element={<About />} /> */}
                  </Route>
                  <Route
                    path={"/login_page"}
                    name={"loginpage"}
                    element={<Login />}
                  />
                  <Route
                    path={"/admin_control"}
                    name={"admincontrol"}
                    element={<AdminControl />}
                  />
                  <Route
                    path={"/registerUser"}
                    name={"userRegistration"}
                    element={<RegisterUsers />}
                  />
                  <Route
                    path={"/document"}
                    name={"document"}
                    element={<DocumentationHome />}
                  >
                    {documentRoutes.map((route, idx) => {
                      return (
                        route.element && (
                          <Route
                            key={idx}
                            path={route.path}
                            name={route.name}
                            element={<route.element />}
                          />
                        )
                      );
                    })}
                  </Route>
                  <Route
                    exact
                    path={`/dashboard`}
                    name="LayOut"
                    element={<LayOut />}
                  >
                    {routes.map((route, idx) => {
                      return (
                        route.element && (
                          <Route
                            key={idx}
                            path={route.path}
                            name={route.name}
                            element={<route.element />}
                          />
                        )
                      );
                    })}
                  </Route>
                </Routes>
              </Suspense>
            </ErrorBoundary>
          </Router>
        </ThemeProvider.Provider>
      </TourProvider>
    </>
  );
}
export default App;
