import React from "react";
//Auth Part
const DashBoard = React.lazy(() =>
  import("../pages/products/taxwonders/DashBoard")
);
const ViewClients = React.lazy(() =>
  import("../pages/products/taxwonders/taxpayerclient/ViewClients")
);
const AddClient = React.lazy(() =>
  import("../pages/products/taxwonders/taxpayerclient/AddClient")
);
const EditClient = React.lazy(() =>
  import("../pages/products/taxwonders/taxpayerclient/EditClient")
);
const TaxClientDashBoard = React.lazy(() =>
  import("../pages/products/taxwonders/taxpayerclient/TaxClientDashBoard")
);
const FinalcialStatement = React.lazy(()=>import("../pages/products/taxwonders/taxpayerclient/FinalcialStatement/FinalcialStatement"));
const _taxwondersRoutes = [
  {
    path: "/dashboard/dshboardhome",
    name: "Dashboard",
    element: DashBoard,
  },
  {
    path: "/dashboard/view_client",
    name: "View Clients",
    element: ViewClients,
  },
  {
    path: "/dashboard/client_dashBoard",
    name: "Client Dashboard",
    element: TaxClientDashBoard,
  },
  {
    path: "/dashboard/add_client",
    name: "Add Client",
    element: AddClient,
  },
  {
    path: "/dashboard/edit_client",
    name: "Edit Client",
    element: EditClient,
  },
  {
    path: "/dashboard/finalcialstatement",
    name: "Finalcial Statement",
    element: FinalcialStatement,
  },
];
export default _taxwondersRoutes;
